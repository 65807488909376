import Card from "../../components/Card"
import tdahCover from '../../assets/images/work/tdah.png';
import odeteCover from '../../assets/images/work/odete-capa.png';
import laBuenaCover from '../../assets/images/work/capa.jpg';

const Projects = ()=>{
    return(
        <div className="projects">
            <div className="cards">
                <a href="https://www.behance.net/gallery/121804921/Redsign-Portal-Odete" target="blank"><Card work={{name: "Redesign Odete", image: odeteCover}}/></a>
                <a href="https://www.behance.net/gallery/122278303/La-Buena-Cena-Spanish-Food-Restaurant" target="blank"><Card work={{name: "La Buena Cena", image: laBuenaCover}}/></a>
                <Card work={{name: "TDAH e organização", image: tdahCover}}/>
            </div>
        </div>
       
    )
}

export default Projects