import fotoPerfil from '../../assets/images/foto-perfil.JPG'

const About = () =>{
    return(
        <div className="about">
            <h4 className="subtitle">Um pouco mais sobre mim</h4>
            <div className="sobre-mim">
                <img src={fotoPerfil} alt="Franciele Cristine" className="foto-perfil" />
                <p className="text">Apaixonada por tecnologia, atualmente busco melhorar a vida das 
                pessoas através da programação web e do design. Programação e design representam para 
                mim muito mais que uma simples linha de código ou uma ilustração bonita. Representam a 
                possibilidade de transformar o dia de alguém ou quem sabe mudar uma vida inteira.</p>
            </div>
            
            <h4 className="subtitle">Como eu cheguei até aqui</h4>
            <p className="text">Após vagar por um tempo pelo espaço sem saber exatamente onde 
            aterrissar encontrei por acaso a programação e me apaixonei completamente por esse 
            vasto universo então decidi entrar de cabeça e aprender desenvolvimento web, javascript, 
            css, html, python, etc. Mas faltava alguma coisa e foi assim que eu descobri o design de 
            experiência de usuário e o design de interface e assim uma porta para um universo ainda
             mais fascinante. </p>
        </div>
        
    )
}

export default About