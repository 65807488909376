import logo from '../../assets/images/logo.png';
import moon from '../../assets/images/moon.svg';
import { useHistory } from 'react-router';
import BackgroundAnimation from '../../components/background animation';

const SplashScreen = () =>{
    const history = useHistory();
    return(
        <div className={'splash-screen'}>
            <img src={logo} alt={'Franciele Cristine'} className={'logo-splash'}/>
            <img src={moon} alt="Moon" className={'Moon'}/>
            <button onClick={() => history.push('/home')} class={"start_button"}>Começar a jornada</button>
        </div>
    )
}

export default SplashScreen
