import Cometa from '../../assets/images/cometa.png'

const BackgroundAnimation = () =>{
    return(
        <div className="background-animation">
            <img src={Cometa} alt="cometa" className="planet" />
            <div className="star"></div>
            <div className="star1"></div>
            <div className="star2"></div>
            <div className="star3"></div>
            <div className="star4"></div>
            <div className="star5"></div>
        </div>
    )
}

export default BackgroundAnimation