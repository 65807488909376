const Contact = () =>{
    return(
        <div className="contact">
            <form className="contact-form">
                <h1 className="contact-title">Vamos bater um papo?</h1>
                <label className="input-label">Qual o seu nome?</label>
                <input className="contact-input"/>
                <label className="input-label">Qual o seu e-mail?</label>
                <input className="contact-input"/>
                <label className="input-label">Sobre o que você quer conversar?</label>
                <textarea className="contat-text-area" />
                <button className="contact-button">Enviar</button>
            </form>
        </div>
        

    )
}

export default Contact