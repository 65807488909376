import './App.css';
import Routes from './helpers/routes';
import '../src/style/index.css'
import Header from "./components/Header/index";
import {useLocation} from "react-router-dom";
import BackgroundAnimation from './components/background animation';

function App() {
    const location = useLocation()

  return (
    <div className="App">
      <BackgroundAnimation/>
        {location.pathname !== '/' ? <Header/> : null}
        <Routes/>
        <div className="footer"></div>
    </div>
  );
}

export default App;
