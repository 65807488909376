import {Route, Switch} from 'react-router-dom';
import SplashScreen from '../pages/SplashScreen/index';
import Home from '../pages/Home';
import Projects from '../pages/Projetos';
import About from '../pages/About';
import Contact from '../pages/Contact';

const Routes = () =>{
    return(
        <Switch>
            <Route path={'/home'}><Home/></Route>
            <Route path={'/projetos'}><Projects/></Route>
            <Route path={'/sobre-mim'}><About/></Route>
            <Route path={'/contato'}><Contact/></Route>
            <Route path={'/'}><SplashScreen/></Route>
        </Switch>
        )

}

export default Routes
