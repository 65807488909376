import MenuWeb from "../Menu/web";
import logo from '../../assets/images/logo.png';
import {useHistory} from 'react-router-dom';
import {HiMenu} from 'react-icons/hi';
import MobileMenu from "../Menu/mobile";
import {useState} from 'react';

const Header = () =>{
    const history = useHistory();
    const [showMenu, setShowMenu] = useState("none")

    return(
        <div className={"header"}>
            <MenuWeb/>
            <img src={logo} alt="Franciele Cristine" className={"menu_logo"} onClick={() => history.push('/home')}/>
            <span className={'menu-icon'} onClick={() => setShowMenu("block")}><HiMenu/></span>
            <div className={"mobile-menu"} style={{display: showMenu}}>
                <MobileMenu setShowMenu={setShowMenu}/>
            </div>
        </div>
    )
}

export default Header
