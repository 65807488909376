import curriculo from '../../assets/files/curriculo ux - pt.pdf';
import alienShip from '../../assets/images/alien ship.svg';

const Home = () =>{
    return(
        <div className={'home'}>
            <div className={"main-content"}>
                <h1 className="home-title">UX/UI Designer</h1>
                <p className="subtitle">do interior de São Paulo - Brasil</p>
                <p className="home-text">
                Apaixonada por tecnologia e arte, encontrei no design a 
                oportunidadede fazer de minha paixões um meio de ajudar as pessoas melhorando o 
                seu dia a dia.
                </p>
            </div>
           
                <a className={"curriculo-link"} href={curriculo} download>Currículo</a>
                <img src={alienShip} alt="alien ship" className="alien-ship"/>
        </div>
    )
}

export default Home
