import {Link} from 'react-router-dom';
import {IoMdClose} from 'react-icons/io';

const MobileMenu = ({setShowMenu}) =>{
    return(
        <div className={'mobile-menu-container'}>
            <div className={"close-icon"} onClick={() => setShowMenu("none")}><IoMdClose/></div>
            <div className={"mobile-menu-item"}>
                <Link className={"mobile-menu-link"} to={"/projetos"}>Projetos</Link>
            </div>
            <div className={"mobile-menu-item"}>
                <Link className={"mobile-menu-link"} to={"/sobre-mim"}>Sobre mim</Link>
            </div>
        </div>
    )
}

export default MobileMenu
