import {Link} from 'react-router-dom';

const MenuWeb = () =>{
    return(
        <div className={'web-menu-container'}>
            <Link className={'web-menu-link'} to={'/projetos'}>Projetos</Link>
            <Link className={'web-menu-link'} to={'/sobre-mim'}>Sobre mim</Link>
        </div>
    )
}

export default MenuWeb
